<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" :sites="sites" :srCmsModules="srCmsModules" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="栏目编号" width="100px" />
      <el-table-column prop="siteName" label="站点名称" width="300px" />
      <el-table-column prop="columnName" label="栏目名称" />
      <el-table-column prop="columnEnglish" label="栏目英文" />
      <el-table-column prop="frontUrl" label="前端跳转地址" />
      <el-table-column label="显示版本" width="100px">
        <template slot-scope="scope">
          <span>{{getLangVersion(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180px">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <!--   <el-table-column prop="upateTime" label="修改时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.upateTime) }}</span>
        </template>
      </el-table-column>-->
      <el-table-column label="状态" width="80px">
        <template slot-scope="scope">
          <span>{{scope.row.status==1?'正常':'锁定'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <edit
            v-if="checkPermission(['ADMIN','SRCMSCOLUMN_ALL','SRCMSCOLUMN_EDIT'])"
            :data="scope.row"
            :sites="sites"
            :sup_this="sup_this"
            :srCmsModules="srCmsModules"
          />
          <el-popover
            v-if="checkPermission(['ADMIN','SRCMSCOLUMN_ALL','SRCMSCOLUMN_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180"
          >
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button
                :loading="delLoading"
                type="primary"
                size="mini"
                @click="subDelete(scope.row.id)"
              >确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
    />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del } from "@/api/cms/srCmsColumn";
import { getModulesData } from "@/api/cms/srCmsModule";
import { getSitesData } from "@/api/cms/srSite";
import { parseTime } from "@/utils/index";
import eHeader from "@/components/cms/column/header";
import edit from "@/components/cms/column/edit";
export default {
  name:'column',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      sites: [],
      srCmsModules: []
    };
  },
  mounted() {
    this.getSites();
    this.getModules();
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    getLangVersion(row){
      if(row.availableLang == 1){
        return '中文';
      }else if(row.availableLang == 2){
        return 'English';
      }else if(row.availableLang == '1,2'){
        return '中文、English';
      }else if(!row.availableLang){
        return '-';
      }
    },
    beforeInit() {
      this.url = "cms/crm/srCmsColumn";
      const sort = "sortNo,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const type = query.type;
      const value = query.value;
      if (type && value) {
        this.params[type] = value;
      }
      if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    getSites() {
      const params = {page:0,size:1000}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    getModules() {
      getModulesData().then(res => {
        for (let i = 0; i < res.content.length; i++) {
          this.srCmsModules.push({
            id: res.content[i].id,
            label: res.content[i].moduleName + "-" + res.content[i].moduleDesc
          });
        }
      });
    }
  }
};
</script>

<style scoped>
</style>
