<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :sites="sites" :is-add="false" :srCmsModules="srCmsModules"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites:{
      type: Array,
      required: true
    },
    srCmsModules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      yesData:[],
    }
  },
  created() {
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        columnName: this.data.columnName,
        upId: this.data.upId,
        columnEnglish: this.data.columnEnglish,
        columnDesc: this.data.columnDesc,
        moduleInfo: this.data.moduleInfo,
        frontUrl: this.data.frontUrl,
        contentUrl: this.data.contentUrl,
        keywords: this.data.keywords,
        availableLang: this.data.availableLang,
        description: this.data.description,
        isShow: this.data.isShow.toString(),
        sortNo: this.data.sortNo,
        status: this.data.status.toString(),
        remark: this.data.remark,
        yesData:this.data.moduleInfo.split(',').map(Number),
      }
      _this.isOut = this.data.contentUrl!=""?'1':'0';
      this.$refs.form.getSrCmsColumns();
      _this.dialog = true;
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
