<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="1000px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="站点名称" prop="siteId">
        <el-select v-model="form.siteId" filterable placeholder="请选择" style="width: 300px;" @change="getSrCmsColumns">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :label="item.siteNameEn+'-'+item.siteName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="栏目名称" prop="columnName">
        <el-input v-model="form.columnName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="栏目英文">
        <el-input v-model="form.columnEnglish" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="栏目描述">
        <el-input v-model="form.columnDesc" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="上级栏目" @load="getSrCmsColumns">
        <treeselect v-model="form.upId" :options="srCmsColumns" style="width: 300px;" placeholder="选择上级栏目" />
      </el-form-item>
      <el-form-item label="链接类型" >
        <el-radio v-model="isOut" label='0'>内链</el-radio>
        <el-radio v-model="isOut" label='1'>外链</el-radio>
      </el-form-item>
      <el-form-item v-if="isOut==1" label="外部链接">
         <el-input v-model="form.contentUrl" style="width: 370px;"/>
      </el-form-item>
      <el-form-item  v-if="isOut==0" label="前端跳转地址">
         <el-input v-model="form.frontUrl" style="width: 370px;"/>
      </el-form-item>
      <el-form-item  v-if="isOut==0" label="组件信息" v-model="form.moduleInfo">
        <el-transfer
            v-model="form.yesData"
            filterable
            :props="{key: 'id',label: 'label'}"
            :titles="['未选中', '已选中']"
            :data="srCmsModules">
        </el-transfer>
      </el-form-item>

      <el-form-item label="关键词">
        <el-input v-model="form.keywords" style="width: 200px;"/>
      </el-form-item>
      <el-form-item label="描述文字">
        <el-input v-model="form.description" style="width: 370px;"/>
      </el-form-item>
      <el-form-item
        label="显示版本"
        prop="availableLang"
      >
        <el-radio v-model="form.availableLang" label="1,2">全部</el-radio>
        <el-radio v-model="form.availableLang" label="1">中文</el-radio>
        <el-radio v-model="form.availableLang" label="2">English</el-radio>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="form.sortNo" style="width: 60px;"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label=1>正常</el-radio>
        <el-radio v-model="form.status" label=0>锁定</el-radio>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="form.remark" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit , getCmsColumnsTree } from '@/api/cms/srCmsColumn'
import Treeselect from '@riophae/vue-treeselect'
import IconSelect from '@/components/IconSelect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { Treeselect, IconSelect },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    sites:{
      type: Array,
      required: true
    },
    yesData:{
      type: Array,
    },
    srCmsModules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      isOut:'0',
      srCmsColumns: [],
      form: {
        id: '',
        siteId: '',
        columnName: '',
        upId: '',
        columnEnglish: '',
        columnDesc: '',
        moduleInfo: '',
        frontUrl:'',
        contentUrl: '',
        keywords: '',
        availableLang: '1,2',
        description: '',
        isShow: '1',
        sortNo: '',
        createTime: '',
        updateTime: '',
        status: '1',
        remark: '',
        yesData:[]
      },
      rules: {
        siteId: [
          { required: true, message: '请选择站点', trigger: 'blur' },
        ],
        columnName: [
          { required: true, message: '请输入栏目名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符(50个汉字)', trigger: 'blur' }
        ],
        isShow: [
          { required: true, message: '请选择是否显示', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true;
      this.form.moduleInfo='';
      for(let i=0;i<this.form.yesData.length;i++){
        if(i==0){
          this.form.moduleInfo = this.form.yesData[i];
        }else{
          this.form.moduleInfo += ','+this.form.yesData[i];
        }
      }
      if(this.isOut==0){
        this.form.contentUrl=''
      }
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.isOut = '0',
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        siteId: '',
        columnName: '',
        upId: '',
        columnEnglish: '',
        columnDesc: '',
        moduleInfo: '',
        frontUrl:'',
        contentUrl: '',
        keywords: '',
        description: '',
        isShow: '1',
        sortNo: '',
        createTime: '',
        updateTime: '',
        status: '1',
        remark: '',
        yesData:[]
      }
    },
    getSrCmsColumns(){
      this.formData = {
        siteId: this.form.siteId,
      }
      getCmsColumnsTree(this.formData).then(res => {
        this.srCmsColumns = [];
        const srCmsColumn = { id: 0, label: 'top栏目', children: [] };
        srCmsColumn.children = res;
        this.srCmsColumns.push(srCmsColumn);
      })
    },
  }
}
</script>

<style>
.el-transfer{
  width: 800px;
}
.el-transfer-panel{
  width: 350px;
  height: 500px;
}
.el-transfer-panel__list.is-filterable{
  height: 380px;
}
.el-transfer-panel__body{
  height: auto;
}
</style>
