import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'cms/crm/srCmsModule',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'cms/crm/srCmsModule/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srCmsModule',
    method: 'put',
    data
  })
}

// 获取所有的组件
export function getModulesData() {
  return request({
    url: 'cms/crm/allSrCmsModule',
    method: 'get'
  })
}
