import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'cms/crm/srCmsColumn',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'cms/crm/srCmsColumn/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srCmsColumn',
    method: 'put',
    data
  })
}

// 获取所有的栏目
export function getCmsColumnsData() {
  return request({
    url: 'cms/crm/srCmsColumn',
    method: 'get'
  })
}

// 获取所有栏目树
export function getCmsColumnsTree(params) {
  return request({
    url: 'cms/crm/srCmsColumn/tree',
    method: 'get',
    params
  })
}


// 获取站点栏目信息
export function getSrCmsColumnsBySiteId(params) {
  return request({
    url: 'community/crm/getSrCmsColumnsBySiteId',
    method: 'get',
    params
  })
}

// 获取站点栏目信息
export function getCmsColumnAndModuleInfo(params) {
  return request({
    url: 'community/crm/getCmsColumnAndModuleInfo',
    method: 'get',
    params
  })
}
